@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes pulse {
  50% {
    background: white;
  }
}
@keyframes pulse {
  50% {
    background: white;
  }
}

.loading {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-bottom: 12px;
  border: 3px solid rgba(109, 109, 109, 0.3);
  border-top-color: rgb(216, 216, 216);
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

.simple_loading {
  border-radius: 50%;
  /* width: 24px;
  height: 24px; */
  border: 3px solid rgba(109, 109, 109, 0.3);
  border-top-color: rgb(216, 216, 216);
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

/* .loading-pulse {
  position: relative;
  width: 6px;
  height: 24px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-animation: pulse 750ms infinite;
          animation: pulse 750ms infinite;
  -webkit-animation-delay: 250ms;
          animation-delay: 250ms;
}
.loading-pulse:before, .loading-pulse:after {
  content: "";
  position: absolute;
  display: block;
  height: 16px;
  width: 6px;
  background: rgba(255, 255, 255, 0.2);
  top: 50%;
  transform: translateY(-50%);
  -webkit-animation: pulse 750ms infinite;
          animation: pulse 750ms infinite;
}
.loading-pulse:before {
  left: -12px;
}
.loading-pulse:after {
  left: 12px;
  -webkit-animation-delay: 500ms;
          animation-delay: 500ms;
} */
